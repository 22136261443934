<template>
  <v-dialog v-model="dialog" width="600" persistent transition="dialog-bottom-transition">
    <v-card tile>
      <v-toolbar flat dark color="primary">
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Detalhes - {{ getSafe(() => campaign.title || campaign.name) || '...' }}</v-toolbar-title>
        <v-spacer />
      </v-toolbar>
      <v-container>
        <v-list v-if="campaign" subheader>
          <v-row no-gutters>
            <v-col cols="6">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Campanha</v-list-item-title>
                  <v-list-item-subtitle>{{ campaign.title || campaign.name || '-' }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Descrição</v-list-item-title>
                  <v-list-item-subtitle>{{ campaign.description || '-' }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Agendamento</v-list-item-title>
                  <v-list-item-subtitle>{{ campaign.scheduled ? 'Ativo' : 'Inativo' }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="campaign.message">
                <v-list-item-content>
                  <v-list-item-title>Data e hora de envio</v-list-item-title>
                  <v-list-item-subtitle>{{ formatDateTime(campaign.message.send_date, campaign.message.send_time, 'msg') }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="6">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Criado em</v-list-item-title>
                  <v-list-item-subtitle>{{ formatDateTime(campaign.created_at, null, 'single') }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Atualizado em</v-list-item-title>
                  <v-list-item-subtitle>{{ formatDateTime(campaign.updated_at, null, 'single') }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
        </v-list>
        <v-divider />
        <v-card-actions class="px-2 pt-4">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" :disabled="!campaign.metrics || !campaign.metrics.last_updated_at" color="error" v-on="on" @click="deleteMetric"
                ><v-icon class="mr-2">mdi-alert-circle</v-icon> Remover métricas</v-btn
              >
            </template>
            <span>Remove o conteúdo da propriedade "metrics" da campanha selecionada</span>
          </v-tooltip>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { QUERY_GET_CAMPAIGN, MUTATION_DELETE_CAMPAIGN_METRIC } from '@/modules/accounts/graphql'

export default {
  props: {
    dialog: Boolean,
    campaignId: String,
    accountId: String
  },
  data: () => ({
    campaign: {}
  }),
  async mounted() {
    await this.getCampaign(this.campaignId)
  },
  methods: {
    async getCampaign(id) {
      try {
        const { data } = await this.$apollo.query({
          query: QUERY_GET_CAMPAIGN,
          fetchPolicy: 'network-only',
          variables: {
            account_id: this.accountId,
            id
          }
        })
        this.campaign = data.accountCampaign
      } catch (err) {
        console.log(err.message)
        this.$snackbar({ message: 'Falha ao buscar dados da campanha', snackbarColor: '#F44336' })
      }
    },
    close() {
      this.$emit('close')
      this.campaign = {}
    },
    formatDateTime(date, time, type) {
      let dateTime
      if (type === 'msg') dateTime = date + 'T' + time + ':00Z'
      if (type === 'single') dateTime = date

      return this.$moment(new Date(dateTime))
        .add(3, 'h')
        .format('YYYY/MM/DD - HH:mm:ss')
    },
    async deleteMetric() {
      const variables = {
        account_id: this.accountId,
        campaign_id: this.campaignId
      }

      try {
        await this.$apollo.mutate({
          mutation: MUTATION_DELETE_CAMPAIGN_METRIC,
          variables
        })
        this.$snackbar({ message: 'Métricas removidas com sucesso', snackbarColor: '#2E7D32' })
        this.close()
      } catch (err) {
        console.log(err.message)
        this.$snackbar({ message: 'Falha ao remover métricas', snackbarColor: '#F44336' })
      }
    }
  }
}
</script>
